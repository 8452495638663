import React, { useState, memo, useEffect } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useLabels } from 'wsm-common-data';
import PropTypes from 'prop-types';
import { useClosestAccounts } from '../hooks/invDataHooks';
import ClosestDealer from './ClosestDealer';
import LocationModal from './LocationModal';

const ClosestDealerWrapper = ({ isMobile }) => {
	const [isOpenModal, setOpenModal] = useState(false);
	const { payload } = useClosestAccounts();
	const labels = useLabels();

	useEffect(() => {
		const subscriber = window?.DDC?.pubsub?.subscribe(
			'location-modal/show-modal',
			() => {
				setOpenModal(true);
			}
		);

		return () => {
			subscriber?.unsubscribe();
		};
	}, []);

	const { data: closestDealers, appliedParams, prefs = {} } = payload || {};

	if (!isBrowser || !closestDealers?.length) {
		return <></>;
	}

	const closestDealer = closestDealers[0];
	const { postalCode } = appliedParams;

	const { showYourStore } = prefs;

	const toggleOpenModal = () => {
		if (showYourStore === 'true') {
			// Open location modal
			setOpenModal((previous) => !previous);
			return;
		}

		window?.DDC?.pubsub?.publish('ws-inv-filters/show-filters-modal');
	};

	const modalTitle = labels.get('YOUR_LOCATION');

	return (
		<>
			{isMobile && (
				<ClosestDealer
					closestDealer={closestDealer}
					userPostalCode={postalCode}
					onClick={toggleOpenModal}
					showYourStore={showYourStore === 'true'}
					containerClassNames={[
						'd-flex',
						'flex-wrap',
						'align-items-center',
						'flex-gap-row-1-rem',
						'mb-4'
					]}
				/>
			)}
			<LocationModal
				closestDealers={closestDealers}
				userPostalCode={postalCode}
				title={modalTitle}
				onClose={toggleOpenModal}
				isOpen={isOpenModal}
				isMobile={isMobile}
			/>
		</>
	);
};

export default memo(ClosestDealerWrapper);

ClosestDealerWrapper.propTypes = {
	isMobile: PropTypes.bool.isRequired
};
