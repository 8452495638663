import React, { useState, useEffect } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useLabels, useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import PropTypes from 'prop-types';

const ClosestDealer = ({
	closestDealer,
	userPostalCode,
	isRenderPortal,
	containerClassNames,
	showYourStore,
	onClick
}) => {
	const [storeName, setStoreName] = useState('');
	const labels = useLabels();

	const { deviceType } = useRequestData();
	const isMobile = deviceType === 'MOBILE';

	const { name } = closestDealer;

	/* TODO: setUserStore have the same code with ws-inv-facets. Need to move setUserStore to common hook on wsm-common-data */
	const setUserStore = () => {
		const userStoreString = window.sessionStorage.getItem('userStore');
		if (userStoreString) {
			const userStore = JSON.parse(userStoreString);
			setStoreName(userStore?.name);
		} else {
			setStoreName(name);
		}
	};

	useEffect(() => {
		setUserStore();
		const subscriber = window?.DDC?.pubsub?.subscribe(
			'location-modal/update-store',
			() => {
				setUserStore();
			}
		);
		return () => {
			subscriber?.unsubscribe();
		};
	}, []);

	if (!isBrowser) {
		return <></>;
	}

	const containerClassNameArr = ['closest-dealer', ...containerClassNames];

	const btnClassNames = [
		'btn',
		'btn-unstyled',
		'd-flex',
		'mb-lg-4',
		'mr-4',
		isMobile ? 'ddc-font-size-small' : ''
	];

	const labelClassNames = [
		'text-muted',
		'mr-3',
		!isRenderPortal ? 'mb-lg-4' : '',
		isMobile ? 'ddc-font-size-small' : 'ddc-font-size-xsmall'
	];

	return (
		<div className={setClassNames(containerClassNameArr)}>
			<div className={setClassNames(labelClassNames)}>
				{labels.get('ESTIMATE_LOCATION')}
			</div>
			<button
				type="button"
				className={setClassNames(btnClassNames)}
				onClick={onClick}
				aria-label={`${userPostalCode}, ${labels.get(
					'ENTER_ANOTHER_ZIP'
				)}`}
			>
				<i
					className="ddc-icon ddc-icon-map-outline2 mr-3"
					aria-hidden="true"
				/>
				{userPostalCode}
			</button>
			{showYourStore && (
				<button
					type="button"
					className={`text-left ${setClassNames(btnClassNames)}`}
					onClick={onClick}
					aria-label={`${storeName}, ${labels.get(
						'CHANGE_YOUR_STORE'
					)}`}
				>
					<i
						className="ddc-icon ddc-icon-store mr-3"
						aria-hidden="true"
					/>
					{storeName}
				</button>
			)}
		</div>
	);
};

export default ClosestDealer;

ClosestDealer.propTypes = {
	isRenderPortal: PropTypes.bool,
	showYourStore: PropTypes.bool,
	closestDealer: PropTypes.shape({
		name: PropTypes.string.isRequired
	}),
	userPostalCode: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	containerClassNames: PropTypes.arrayOf(PropTypes.string)
};

ClosestDealer.defaultProps = {
	isRenderPortal: false,
	containerClassNames: [],
	showYourStore: false
};
